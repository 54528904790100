<template>
  <section class="graph-wrapper">
    <header class="graph-legend d-flex justify-content-between">
      <p v-if="segmentedInterval === 0" class="graph-legend-text">{{ timestamps[0] | moment }}</p>
      <p v-else class="graph-legend-text">{{ (timestamps[0] - segmentedInterval) | moment }}</p>
      <p class="graph-legend-text font-weight-bold">{{ timelineTitleFromProps }}</p>
      <p
        v-if="playMode === false"
        @click.stop="playButtonClick"
        @dblclick.stop="playButtonClick"
        role="button"
        class="play-button btn-outline-success active"
      >
        <i class="fa fa-play" aria-hidden="true"></i>
        {{ $t('maps.playButton') }}
      </p>
      <p
        v-if="playMode === true"
        @click.stop="stopButtonClick"
        @dblclick.stop="stopButtonClick"
        role="button"
        class="play-button btn-outline-danger active"
      >
        <i class="fa fa-stop" aria-hidden="true"></i>
        {{ $t('maps.stopButton') }}
      </p>
      <p class="graph-legend-text">{{ timestamps[timestamps.length - 1] | moment }}</p>
    </header>

    <main class="graph-container">
      <div
        v-for="(number, index) in clients"
        :key="index"
        :id="`column-${index}`"
        class="graph-column"
        @click="wrapped(index)"
        :style="{ width: `calc(100% / ${clients.length})` }"
      >
        <div
          class="graph-bar"
          :class="{ 'graph-bar--selected': selected === index }"
          :style="{ height: `${getPercent(number)}%` }"
        ></div>
        <div class="graph-tooltip" v-bind:class="{ 'graph-tooltip-in-playmode': playMode }">
          <p v-if="segmentedInterval !== 0" class="graph-tooltip-text small-text-for-dates">
            {{ (timestamps[index] - segmentedInterval) | momentSmallFormat }}
          </p>
          <p v-if="segmentedInterval !== 0" class="graph-tooltip-text small-text-for-dates text-center">-</p>
          <p v-if="segmentedInterval !== 0" class="graph-tooltip-text small-text-for-dates">
            {{ timestamps[index] | momentSmallFormat }}
          </p>
          <p v-if="segmentedInterval === 0" class="graph-tooltip-text">{{ timestamps[index] | moment }}</p>
          <br />
          <p v-if="!counterInBytes" class="graph-tooltip-text font-weight-bold">
            <span class="font-weight-normal">{{ timelineTooltipCaptionFromProps }}</span>
            : {{ number }}
          </p>
          <p v-else class="graph-tooltip-text font-weight-bold">
            <span class="font-weight-normal">{{ timelineTooltipCaptionFromProps }}</span>
            : {{ commonService.formatBytes(number) }}
          </p>
        </div>
        <div
          v-if="playMode === true"
          v-show="isShowingPlaymodeGraphTooltip(index, playModeColumnIndex)"
          class="graph-tooltip playmode-graph-tooltip animated fadeIn"
        >
          <p v-if="segmentedInterval !== 0" class="graph-tooltip-text small-text-for-dates">
            {{ (timestamps[index] - segmentedInterval) | momentSmallFormat }}
          </p>
          <p v-if="segmentedInterval !== 0" class="graph-tooltip-text small-text-for-dates text-center">-</p>
          <p v-if="segmentedInterval !== 0" class="graph-tooltip-text small-text-for-dates">
            {{ timestamps[index] | momentSmallFormat }}
          </p>
          <p v-if="segmentedInterval === 0" class="graph-tooltip-text">{{ timestamps[index] | moment }}</p>
          <br />
          <p v-if="!counterInBytes" class="graph-tooltip-text font-weight-bold">
            <span class="font-weight-normal">{{ timelineTooltipCaptionFromProps }}</span>
            : {{ number }}
          </p>
          <p v-else class="graph-tooltip-text font-weight-bold">
            <span class="font-weight-normal">{{ timelineTooltipCaptionFromProps }}</span>
            : {{ commonService.formatBytes(number) }}
          </p>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import commonService from '../../services/commonService';

export default {
  name: 'MapsVisitors',
  props: {
    clients: { type: Array, default: () => [] },
    timestamps: { type: Array, required: true },
    switchSegment: { type: Function, required: true },
    selected: { type: Number, default: -1 },
    segmentedInterval: { type: Number, default: 0 },
    timelineTitle: { type: String, default: '' },
    timelineTooltipCaption: { type: String, default: '' },
    counterInBytes: { type: Boolean, default: false }
  },
  data() {
    return {
      playMode: false,
      playModeColumnIndex: 0,
      playTimerIntervalObject: null
    };
  },
  methods: {
    isShowingPlaymodeGraphTooltip(index, playModeColumnIndex) {
      if (playModeColumnIndex === 0 && index === this.maxColumnIndex) {
        return true;
      }
      if (index === playModeColumnIndex - 1) {
        return true;
      }
      return false;
    },
    playModeStep() {
      const currentColumnIndex = this.playModeColumnIndex;
      // console.log(currentColumnIndex);
      this.switchSegment(currentColumnIndex);
      if (currentColumnIndex < this.maxColumnIndex) {
        this.playModeColumnIndex += 1;
      } else {
        this.playModeColumnIndex = 0;
      }
    },
    playButtonClick() {
      // включаем режим автоматического перебора периодов
      this.playMode = true;
      // console.log('play');
      this.playTimerIntervalObject = setInterval(() => {
        this.playModeStep();
      }, 1500);
    },
    stopButtonClick() {
      // выключаем режим автоматического перебора периодов
      this.playMode = false;
      if (this.playTimerIntervalObject !== null) {
        clearInterval(this.playTimerIntervalObject);
      }
      this.playTimerIntervalObject = null;
    },
    wrapped(index) {
      if (index + 1 > this.maxColumnIndex) {
        this.playModeColumnIndex = 0;
      } else {
        this.playModeColumnIndex = index + 1;
      }
      this.switchSegment(index);
    },
    getPercent(value) {
      return (value / this.max) * 100;
    }
  },
  computed: {
    commonService() {
      return commonService;
    },
    timelineTitleFromProps() {
      if (this.timelineTitle === '') {
        return this.$t('maps.timelineTitle');
      }
      return this.timelineTitle;
    },
    timelineTooltipCaptionFromProps() {
      if (this.timelineTooltipCaption === '') {
        return this.$t('maps.Clients');
      }
      return this.timelineTooltipCaption;
    },
    max() {
      return Math.max(...this.clients);
    },
    maxColumnIndex() {
      return Object.keys(this.clients).length - 1;
    }
  },
  filters: {
    moment(value) {
      return moment.unix(value).format('D MMM YYYY, HH:mm');
    },
    momentSmallFormat(value) {
      return moment.unix(value).format('DD.MM.YY,  HH:mm');
    }
  },
  beforeDestroy() {
    // убираем заданный интервал вызова функции перебора периодов,
    // что-бы перебор не продолжался после закрытия компонента
    if (this.playTimerIntervalObject !== null) {
      clearInterval(this.playTimerIntervalObject);
    }
  }
};
</script>

<style lang="scss" scoped>
.small-text-for-dates {
  font-size: 0.9em;
}
.graph-wrapper {
  padding: 5px;

  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: white;

  cursor: default;
}

.graph-container {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  cursor: pointer;
}

.graph-column {
  position: relative;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  padding-left: 2px;
  padding-right: 2px;
}

.graph-bar {
  width: 100%;
  min-height: 2px;

  background-color: #81c784;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
}

.graph-column:hover .graph-bar {
  opacity: 1;
}

.graph-bar--selected {
  background-color: #ff8f07;
}

.graph-tooltip {
  position: absolute;
  top: -40px;
  right: 50%;
  z-index: 500;

  display: none;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 10px;
  min-width: 100px;

  font-size: 12px;

  border: 1px solid #ff8f07;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);

  transform: translateY(-50%);
}
.graph-tooltip-in-playmode {
  top: -56px !important;
}
.playmode-graph-tooltip {
  position: absolute;
  display: block;
  border: 1px solid #1a6aff;
  top: 0px;
  right: 50%;
  z-index: 400;
  animation-duration: 700ms !important;
}

.graph-column:hover .graph-tooltip {
  display: block;
}

.graph-tooltip-text {
  display: block;
  margin: 0;
}

.graph-legend {
  margin-bottom: 10px;
}

.graph-legend-text {
  margin: 0;
}
.play-button {
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: white;
}
</style>
