<template>
  <section class="heatmap-legend-wrapper">
    <main class="">
      <div class="header">
        {{$t('maps.heatmapLegendHeader')}}
      </div>

      <div class="item-string" v-for="key of legendFotHeatmpaKeys" :key="key">
        <div v-if="(colors[key].min_value !== 0 &&  colors[key].max_value !== 0) || key === '0'"
             :data-color="colors[key].color" @mouseover="mouseOver(colors[key].color)"
             @mouseleave="mouseLeave(colors[key].color)"
             role="button"
             class="d-flex flex-row justify-content-between align-items-center item-in-heatmap-legend">
          <div class="color-rectangle"  :style="{'background-color': colors[key].color, 'opacity': key === '0' ? 0.3 : 1 }" >

          </div>
          <div v-if="key ==='0'" class="info-block">
            {{colors[key].min_value}}
          </div>
          <div v-else-if="key ==='100'" class="info-block">
            {{colors[key].max_value}}
          </div>
          <div v-else class="info-block">
            <span v-if="colors[key].min_value !== colors[key].max_value">{{colors[key].min_value}}-{{colors[key].max_value}}</span>
            <span v-else>{{colors[key].min_value}}</span>
          </div>
        </div>
<!--        <div v-else :data-color="colors[key].color" @mouseover="mouseOver(colors[key].color)"-->
<!--             @mouseleave="mouseLeave(colors[key].color)"-->
<!--             role="button"-->
<!--             class="d-flex flex-row justify-content-start align-items-center item-in-heatmap-legend">-->
<!--          <div class="color-rectangle" :style="{'background-color': colors[key].color, 'opacity': key === '0' ? 0.3 : 1 }" >-->

<!--          </div>-->
<!--          <div class="info-block">-->
<!--            {{$t('maps.noData')}}-->
<!--          </div>-->
<!--        </div>-->
        <div v-else :data-color="colors[key].color">
        </div>
      </div>

    </main>
  </section>
</template>

<script>

const LEGEND_FOR_HEATMAP_KEYS = ['0', '1_5', '6_10', '11_15', '16_20', '21_25', '26_30', '31_35', '36_40', '41_45', '46_50', '51_55', '56_60', '61_65', '66_70', '71_75', '76_80', '81_85', '86_90', '91_95', '96_99', '100']

export default {
  name: 'MapsHeatmapLegend',
  props: {
    heatmapLegendData:{type: Object}
  },
  data() {
    return {
    };
  },
  methods: {
    mouseOver(color) {
      // console.log('over', color)
      const nodes = document.querySelectorAll(`path[fill="${color}"]`)
      if (nodes) {
        nodes.forEach((node) => {
          node.classList.add('pulse-heatmap-legend-item')
        })
      }
      // document.querySelector(`[fill="${color}"]`).classList.add('pulse-heatmap-legend-item')

    },
    mouseLeave(color) {
      // console.log('leave', color)
      // document.querySelector(`[fill="${color}"]`).classList.remove('pulse-legend-item')
      const nodes = document.querySelectorAll(`path[fill="${color}"]`)
      if (nodes) {
        nodes.forEach((node) => {
          node.classList.remove('pulse-heatmap-legend-item')
        })
      }


    }
  },
  computed: {
    maxValue() {
      return this.heatmapLegendData.maxValue
    },
    colors() {
      return this.heatmapLegendData.colors
    },
    legendFotHeatmpaKeys() {
      return LEGEND_FOR_HEATMAP_KEYS;
    }

  },
  filters: {
    moment(value) {
      return moment.unix(value).format('D MMM YYYY, HH:mm');
    },
    momentSmallFormat(value) {
      return moment.unix(value).format('DD.MM.YY,  HH:mm');
    }
  },
};
</script>

<style lang="scss" scoped>
  .color-rectangle{
    width: 7px;
    height: 7px;
    margin-left: 0.1em;
  }
  .item-string:hover {
    background: rgba(0,0,0,0.1);

  }
  .heatmap-legend-wrapper .heatmap-legend-item-highlited {
    background: rgba(0,0,0,0.1);
  }
  .info-block{
    font-size: 0.80em;
    font-family: monospace;
    margin-left: 0.25em;


  }
  .header{
    font-size: 0.9em;
    text-align: center;
    margin-bottom: 0.15em;
  }

.heatmap-legend-wrapper {
  padding: 5px;

  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: white;

  cursor: default;

}

</style>
